<script setup lang="ts">
import { useOnboardingStore } from '@/stores/onboarding';
import type { IOnboardingStep } from '@/types/IAssetType';
import { ref, watch } from 'vue';


const onboardingStore = useOnboardingStore()

const myStep = ref(onboardingStore.steps.find(x => x.tag === 'SelectAssetTypes') as IOnboardingStep)

watch(
    () => [onboardingStore.assetTypes],
    () => {
        myStep.value.canGoNext = onboardingStore.assetTypes.some(x => x.isSelected === true);
        onboardingStore.assetTypes.forEach((asset) => {

            const steps = onboardingStore.steps.filter(x => x.assetType !== undefined && x.assetType === asset.title);
            if (steps && steps.length > 0) {
                steps.forEach(st => st.show = asset.isSelected)
            }

        })

    },
    { deep: true }
);

</script>

<template>

    <div class=" bg-slate-300 -mx-4 -mt-5 sm:-m-6  p-4 sm:px-6 sm:py-4 ">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Assets</h3>
        <p class="mt-0 max-w-4xl text-sm text-gray-500">Please select asset types which you want to track.</p>
    </div>


    <div class="mt-10 mb-6">

        <fieldset>
            <legend class="sr-only">Notifications</legend>
            <div class="space-y-5 select-none">
                <label :for="'comments' + index" class="relative flex items-start cursor-pointer group " v-for="(item, index) in onboardingStore.assetTypes" :key="index">
                    <div class="flex h-6 items-center">
                        <input v-model="item.isSelected" :id="'comments' + index" aria-describedby="comments-description" :name="'comments' + index" type="checkbox"
                            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    </div>
                    <div class="ml-3 text-sm leading-6">
                        <p class="font-medium text-gray-900 group-hover:text-indigo-600 select-none cursor-pointer" :class="[item.isSelected && 'text-indigo-700']">{{ item.title }}</p>
                        <p id="comments-description" class="text-gray-500 group-hover:text-gray-900">{{ item.description }}</p>
                    </div>
                </label>
            </div>
        </fieldset>

    </div>


</template>