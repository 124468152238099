<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ref, watch } from 'vue';
import SelectUsageTypesComponent from './SelectAssetTypesComponent.vue';
import { useOnboardingStore } from '@/stores/onboarding';
import AddStockAccountComponent from './AddStockAccountComponent.vue';
import AddStockComponent from './AddStockComponent.vue';
import AddCashAccountComponent from './AddCashAccountComponent.vue';
import FinalComponent from './FinalComponent.vue';
import { useAuthStore } from '@/stores/auth';


const onboardingStore = useOnboardingStore()
const authStore = useAuthStore()




</script>

<template>
    <TransitionRoot as="template" :show="onboardingStore.show">
        <Dialog class="relative z-10">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg ">
                            <!-- px-4 pb-4 pt-5 sm:p-6 -->
                            <div class="p-8 bg-gray-200">
                                <nav class="flex items-center justify-center" aria-label="Progress">
                                    <p class="text-sm font-medium">Step {{ onboardingStore.getCurrentStepIndex + 1 }} of {{ onboardingStore.getSteps.length }}</p>
                                    <ol role="list" class="ml-8 flex items-center space-x-5">
                                        <li v-for="step in onboardingStore.getSteps" :key="step.name">
                                            <div v-if="onboardingStore.getStepIndex(step) < onboardingStore.getCurrentStepIndex"
                                                class="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900">
                                                <span class="sr-only">{{ step.name }}</span>
                                            </div>
                                            <div v-else-if="onboardingStore.getCurrentStep.name === step.name" class="relative flex items-center justify-center" aria-current="step">
                                                <span class="absolute flex h-5 w-5 p-px" aria-hidden="true">
                                                    <span class="h-full w-full rounded-full bg-indigo-200" />
                                                </span>
                                                <span class="relative block h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                                                <span class="sr-only">{{ step.name }}</span>
                                            </div>
                                            <div v-else class="block h-2.5 w-2.5 rounded-full bg-gray-400 hover:bg-gray-500">
                                                <span class="sr-only">{{ step.name }}</span>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="px-4 pb-4 pt-5 sm:p-6">
                                <SelectUsageTypesComponent v-if="onboardingStore.getCurrentStep.tag === 'SelectAssetTypes'"></SelectUsageTypesComponent>
                                <AddStockAccountComponent v-if="onboardingStore.getCurrentStep.tag === 'AddStockAccount'"></AddStockAccountComponent>
                                <AddStockComponent v-if="onboardingStore.getCurrentStep.tag === 'AddStock'"></AddStockComponent>
                                <AddCashAccountComponent v-if="onboardingStore.getCurrentStep.tag === 'AddCashAccount'"></AddCashAccountComponent>
                                <FinalComponent v-if="onboardingStore.getCurrentStep.tag === 'Final'"></FinalComponent>
                            </div>

                            <div class="bg-gray-200 px-4 py-3 flex sm:px-6 space-x-2  items-center">
                                <div v-if="onboardingStore.setupDone === false" class="text-sm text-indigo-600 font-medium hover:underline hover:text-indigo-500 cursor-pointer"
                                    @click="onboardingStore.show = false">Skip setup</div>
                                <div class="flex-grow"></div>
                                <button type="button" :disabled="!onboardingStore.getCanGoBack" v-if="onboardingStore.getCanGoBack"
                                    class="inline-flex w-full justify-center rounded-md bg-white px-6 py-2 text-sm font-semibold text-gray-900 disabled:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    @click="onboardingStore.goBack" ref="cancelButtonRef">Back</button>
                                <button type="button" :disabled="!onboardingStore.getCanGoNext"
                                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 disabled:bg-gray-300 px-6 py-2 text-sm font-semibold text-white disabled:text-gray-500 shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                    @click="onboardingStore.goNext">Next</button>

                            </div>


                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>