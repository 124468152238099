<script setup lang="ts">
import { computed } from 'vue';


const version = computed(() => {
  return import.meta.env.VITE_VERSION;
})
</script>

<template>
  <div class="flex min-h-full flex-1">
    <div class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <RouterView></RouterView>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <img class="absolute inset-0 h-full w-full object-cover"
        src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80" alt="" />
      <div class="absolute right-4 top-4 text-sm text-gray-500">version {{ version }}</div>
    </div>
  </div>
</template>
