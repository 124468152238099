export class MyStockTicker {
  id!: number;
  ticker!: string;
  name!: string | null;
  typeOfEquity!: string;

  currentPrice!: number | null;
  currentPriceDate!: string | null;
  currentPriceGettedDate!: Date | null;
  currentPriceSource!: string | null;

  lastClosePrice!: number | null;
  lastClosePriceDate!: string | null;
  lastClosePriceGettedDate!: Date | null;
  lastClosePriceSource!: string | null;

  isMarketOpen!: boolean | null;

  constructor(id: number, ticker: string, name: string | null, typeOfEquity: string, isMarketOpen: boolean | null) {
    this.id = id;
    this.ticker = ticker;
    this.name = name;
    this.typeOfEquity = typeOfEquity;
    this.isMarketOpen = isMarketOpen;
  }

  get dayChange(): number | undefined {
    if (this.isMarketOpen && this.currentPrice && this.lastClosePrice) {
      return (this.currentPrice - this.lastClosePrice) / this.lastClosePrice;
    }
    return undefined;
  }
}
