import { defineStore } from "pinia";
import type {
  AccountTypeEnum,
  ApiResult,
  CurrenyItem,
  EnumItem,
  ExchangeRate,
  GetAccountsRequest,
  GetAccountsResult,
  GetAccountsResultItem,
  GetNotesRequest,
  GetNotesResult,
  GetStockPositionsRequest,
  GetStockPositionsResult,
  GetStockPricesRequest,
  GetStockPricesResult,
  GetStockTickersRequest,
  GetStockTickersResult,
  GetStockTransactionsResultItem,
  GetFundPositionsRequest,
  GetFundPositionsResult,
  GetFundPricesRequest,
  GetFundPricesResult,
  GetFundTickersRequest,
  GetFundTickersResult,
  GetFundTransactionsResultItem,
  GetCoinPositionsRequest,
  GetCoinPositionsResult,
  GetCoinPricesRequest,
  GetCoinPricesResult,
  GetCoinTickersRequest,
  GetCoinTickersResult,
  GetCoinTransactionsResultItem,
  GetTransfersResultItem,
  NoteTypeEnum,
  SignUpResult,
  UpdateStockPriceCommand,
  UpdateFundPriceCommand,
  UpdateCoinPriceCommand,
} from "@/types/SharedTypes";
import type { NotificationItem, NotificationItemType } from "@/types/NotificationItem";
import { nanoid } from "nanoid";
import axios from "axios";
import { MyAccount } from "@/types/MyAccount";
import { MyStockPosition } from "@/types/MyStockPosition";
import { MyFundPosition } from "@/types/MyFundPosition";
import { MyCoinPosition } from "@/types/MyCoinPosition";
import { MyStockTicker } from "@/types/MyStockTicker";
import { MyFundTicker } from "@/types/MyFundTicker";
import { MyCoinTicker } from "@/types/MyCoinTicker";
import * as signalR from "@microsoft/signalr";
import { HubConnectionState } from "@microsoft/signalr";
import type { ILog } from "@/types/MyLog";
import { useAuthStore } from "@/stores/auth";
import { MyExchangeRate } from "@/types/MyExchangeRate";
import { arrayDifferenceAndIntersection } from "@/types/ArrayHelper";

export const useCounterStore = defineStore("counter", {
  state: () => ({
    notifications: [] as Array<NotificationItem>,
    accountTypeEnums: [] as Array<EnumItem>,
    noteTypeEnums: [] as Array<EnumItem>,
    currencies: [] as Array<CurrenyItem>,
    goldTypes: [] as Array<CurrenyItem>,
    exchangeRates: [] as Array<ExchangeRate>,

    stockTickers: [] as Array<MyStockTicker>,
    fundTickers: [] as Array<MyFundTicker>,
    coinTickers: [] as Array<MyCoinTicker>,
    stockPositions: null as Array<MyStockPosition> | null,
    fundPositions: null as Array<MyFundPosition> | null,
    coinPositions: null as Array<MyCoinPosition> | null,
    accounts: null as Array<MyAccount> | null,
    logs: [] as Array<ILog>,
    intervalIdForExchangeRates: undefined as number | undefined,
    hubState: undefined as HubConnectionState | undefined,
    apiUrl: "",
    showDrawer: "",
    edittingAccount: undefined as undefined | MyAccount,
    edittingTransfer: undefined as undefined | GetTransfersResultItem,
    addEditTransferMode: undefined as undefined | ("AddIncome" | "AddTransfer" | "AddExpense" | "AddForeignExchangeTransfer"),
    addEditTransferToAccountId: undefined as undefined | number,
    addEditTransferFromAccountId: undefined as undefined | number,
    edittingStockTransaction: undefined as undefined | GetStockTransactionsResultItem,
    edittingFundTransaction: undefined as undefined | GetFundTransactionsResultItem,
    edittingCoinTransaction: undefined as undefined | GetCoinTransactionsResultItem,
    addEditNoteStockPositionId: null as null | number,
    addEditNoteFundPositionId: null as null | number,
    addEditNoteCoinPositionId: null as null | number,
  }),
  getters: {
    getAccountTypeEnumName: (state) => {
      return (accountTypeEnum: AccountTypeEnum | Number) => state.accountTypeEnums.find((x) => x.key === accountTypeEnum)?.description;
    },
    getNoteTypeEnumName: (state) => {
      return (noteTypeEnum: NoteTypeEnum | Number) => state.noteTypeEnums.find((x) => x.key === noteTypeEnum)?.description;
    },
    // getStockPositions: (state) => {
    //   if (state.accounts) {
    //     return state.accounts.filter((x) => x.stockPositions !== undefined && x.stockPositions.length > 0).flatMap((x) => x.stockPositions);
    //   }
    //   return [];
    // },
  },
  actions: {
    setApiUrl(newData: string) {
      this.apiUrl = newData;
    },
    addNotification(title: string, text: string, type: NotificationItemType, subItems: string[] | undefined = undefined) {
      const notificationItem = { id: nanoid(), title: title, text: text, subItems: subItems, type: type } as NotificationItem;
      this.notifications.push(notificationItem);
      setTimeout(() => {
        this.notifications.splice(
          this.notifications.findIndex((n) => n.id === notificationItem.id),
          1
        );
      }, 4000);
    },
    closeNotification(notificationItemId: string) {
      const index = this.notifications.findIndex((n) => n.id === notificationItemId);
      if (index >= 0) this.notifications.splice(index, 1);
    },
    async start() {
      this.addInfo("Store.", "Start.");

      this.connectToHub();

      await this.fetchAccountTypeEnumsAsync();
      await this.fetchNoteTypeEnumsAsync();
      await this.fetchCurrencies();
      await this.fetchGoldTypes();
      await this.fetchExchangeRates();

      await this.fetchStockTickersOneTimeAsync();
      await this.fetchFundTickersOneTimeAsync();
      await this.fetchCoinTickersOneTimeAsync();
      await this.fetchStockPositionsOneTimeAsync();
      await this.fetchFundPositionsOneTimeAsync();
      await this.fetchCoinPositionsOneTimeAsync();
      await this.fetchAccountsOneTimeAsync();

      await this.fetchStockPricesAsync();
      await this.fetchFundPricesAsync();
      await this.fetchCoinPricesAsync();
      await this.fetchNotesAsync();

      this.startTimer();
    },
    startTimer() {
      // this.intervalIdForExchangeRates = window.setInterval(this.fetchExchangeRates, 60 * 1000);
    },

    addError(functionName: string, message: string) {
      this.logs.push({ type: "Error", functionName: functionName, message: message, date: new Date() } as ILog);
    },
    addErrorWithDetails(functionName: string, message: string, details: string) {
      this.logs.push({ type: "Error", functionName: functionName, message: message, details: details, date: new Date() } as ILog);
    },
    addExceptionWithDetails(functionName: string, e: unknown) {
      let details = "";
      if (typeof e === "string") {
        details = e.toUpperCase(); // works, `e` narrowed to string
      } else if (e instanceof Error) {
        details = e.message; // works, `e` narrowed to Error
      }
      this.logs.push({ type: "Exception", functionName: functionName, message: "", details: details, date: new Date() } as ILog);
    },
    addInfo(functionName: string, message: string) {
      this.logs.push({ type: "Info", functionName: functionName, message: message, date: new Date() } as ILog);
    },
    async fetchAccountTypeEnumsAsync() {
      this.addInfo("fetchAccountTypeEnumsAsync", "Start.");
      try {
        const response = await axios.post<ApiResult<EnumItem[]>>(this.apiUrl + "Enum/GetAccountTypeEnums");
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchAccountTypeEnumsAsync", "error.", response.data.error);
          } else {
            this.accountTypeEnums = response.data.result;
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchAccountTypeEnumsAsync", error);
      }
    },
    async fetchNoteTypeEnumsAsync() {
      this.addInfo("fetchNoteTypeEnumsAsync", "Start.");
      try {
        const response = await axios.post<ApiResult<EnumItem[]>>(this.apiUrl + "Enum/GetNoteTypeEnums");
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchNoteTypeEnumsAsync", "error.", response.data.error);
          } else {
            this.noteTypeEnums = response.data.result;
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchNoteTypeEnumsAsync", error);
      }
    },
    async fetchCurrencies() {
      this.addInfo("fetchCurrencies", "Start.");
      try {
        const response = await axios.post<ApiResult<CurrenyItem[]>>(this.apiUrl + "Currency/GetCurrencies");
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchCurrencies", "error.", response.data.error);
          } else {
            this.currencies = response.data.result;
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchCurrencies", error);
      }
    },
    async fetchGoldTypes() {
      this.addInfo("fetchGoldTypes", "Start.");
      try {
        const response = await axios.post<ApiResult<CurrenyItem[]>>(this.apiUrl + "Currency/GetGoldTypes");
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchGoldTypes", "error.", response.data.error);
          } else {
            this.goldTypes = response.data.result;
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchGoldTypes", error);
      }
    },
    async fetchExchangeRates() {
      this.addInfo("fetchExchangeRates", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const response = await axios.post<ApiResult<ExchangeRate[]>>(this.apiUrl + "ExchangeRate/GetRates", undefined, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchCurrencies", "error.", response.data.error);
          } else {
            this.exchangeRates = response.data.result.map((x) => new MyExchangeRate(x.symbol, x.price, x.date)) as MyExchangeRate[];
            this.updateAccountsForNewExchangeRate();
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchCurrencies", error);
      }
    },
    async fetchAccountsOneTimeAsync() {
      this.addInfo("fetchAccountsOneTimeAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetAccountsRequest;
        const response = await axios.post<ApiResult<GetAccountsResult>>(this.apiUrl + "Account/GetAccounts", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchAccountsOneTimeAsync", "error.", response.data.error);
          } else {
            this.accounts = response.data.result.items.map((x) => new MyAccount(x.id, x.amount, x.currency, x.name, x.description, x.accountType, x.cashAccountId, x.investingAccountId)) as MyAccount[];
            this.addInfo("fetchAccountsOneTimeAsync", "Getted " + this.accounts.length + " items.");
            this.accounts.forEach((a) => {
              if (a.currency !== "USD") {
                const exchangeRate = this.exchangeRates.find((x) => x.symbol === "USD" + a.currency);
                if (exchangeRate === undefined) {
                  this.addError("fetchAccountsOneTimeAsync", "Cannot find exchangeRate.");
                } else {
                  a.exchangeRate = exchangeRate;
                }
              }
            });
            this.stockPositions?.forEach((sp) => {
              const account = this.accounts?.find((a) => a.id === sp.accountId);
              if (account === undefined) {
                this.addError("fetchAccountsOneTimeAsync", "Cannot find account.");
              } else {
                sp.account = account;
                if (account.stockPositions === undefined || account.stockPositions === null) {
                  account.stockPositions = [];
                }
                account.stockPositions.push(sp);
              }
            });
            this.fundPositions?.forEach((sp) => {
              const account = this.accounts?.find((a) => a.id === sp.accountId);
              if (account === undefined) {
                this.addError("fetchAccountsOneTimeAsync", "Cannot find account.");
              } else {
                sp.account = account;
                if (account.fundPositions === undefined || account.fundPositions === null) {
                  account.fundPositions = [];
                }
                account.fundPositions.push(sp);
              }
            });
            this.coinPositions?.forEach((sp) => {
              const account = this.accounts?.find((a) => a.id === sp.accountId);
              if (account === undefined) {
                this.addError("fetchAccountsOneTimeAsync", "Cannot find account.");
              } else {
                sp.account = account;
                if (account.coinPositions === undefined || account.coinPositions === null) {
                  account.coinPositions = [];
                }
                account.coinPositions.push(sp);
              }
            });
            this.updateAccountsForNewExchangeRate();
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchAccountsOneTimeAsync", error);
      }
    },
    async fetchStockPricesAsync() {
      this.addInfo("fetchStockPricesAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetStockPricesRequest;
        const response = await axios.post<ApiResult<GetStockPricesResult>>(this.apiUrl + "StockPrice/GetStockPrices", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchStockPricesAsync", "error.", response.data.error);
          } else {
            if (response.data.result.items) {
              response.data.result.items.forEach((x) => {
                const clientStockTicker = this.stockTickers.find((st) => st.id === x.tickerId);
                if (clientStockTicker === undefined) {
                  this.addError("fetchStockPricesAsync", "Cannot find stockTicker.");
                } else {
                  clientStockTicker.currentPrice = x.realTimePrice;
                  clientStockTicker.lastClosePrice = x.lastClosePrice;
                  clientStockTicker.lastClosePriceDate = x.lastCloseDate;
                  clientStockTicker.lastClosePriceGettedDate = new Date();
                  clientStockTicker.lastClosePriceSource = x.lastClosePriceSource;
                }
              });
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchStockPricesAsync", error);
      }
    },
    async fetchFundPricesAsync() {
      this.addInfo("fetchFundPricesAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetFundPricesRequest;
        const response = await axios.post<ApiResult<GetFundPricesResult>>(this.apiUrl + "FundPrice/GetFundPrices", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchFundPricesAsync", "error.", response.data.error);
          } else {
            if (response.data.result.items) {
              this.addInfo("fetchFundPricesAsync", "Getted " + response.data.result.items.length + " items.");
              response.data.result.items.forEach((x) => {
                const clientFundTicker = this.fundTickers.find((st) => st.id === x.tickerId);
                if (clientFundTicker === undefined) {
                  this.addError("fetchFundPricesAsync", "Cannot find fundTicker.");
                } else {
                  clientFundTicker.currentPrice = x.realTimePrice;
                  clientFundTicker.lastClosePrice = x.lastClosePrice;
                  clientFundTicker.lastClosePriceDate = x.lastCloseDate;
                  clientFundTicker.lastClosePriceGettedDate = new Date();
                  clientFundTicker.lastClosePriceSource = x.lastClosePriceSource;
                }
              });
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchFundPricesAsync", error);
      }
    },
    async fetchCoinPricesAsync() {
      this.addInfo("fetchCoinPricesAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetCoinPricesRequest;
        const response = await axios.post<ApiResult<GetCoinPricesResult>>(this.apiUrl + "CoinPrice/GetCoinPrices", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchCoinPricesAsync", "error.", response.data.error);
          } else {
            if (response.data.result.items) {
              this.addInfo("fetchCoinPricesAsync", "Getted " + response.data.result.items.length + " items.");
              response.data.result.items.forEach((x) => {
                const clientCoinTicker = this.coinTickers.find((st) => st.id === x.tickerId);
                if (clientCoinTicker === undefined) {
                  this.addError("fetchCoinPricesAsync", "Cannot find coinTicker.");
                } else {
                  clientCoinTicker.currentPrice = x.realTimePrice;
                  clientCoinTicker.lastClosePrice = x.lastClosePrice;
                  clientCoinTicker.lastClosePriceDate = x.lastCloseDate;
                  clientCoinTicker.lastClosePriceGettedDate = new Date();
                  clientCoinTicker.lastClosePriceSource = x.lastClosePriceSource;
                }
              });
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchCoinPricesAsync", error);
      }
    },
    async fetchNotesAsync() {
      this.addInfo("fetchNotesAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetNotesRequest;
        const response = await axios.post<ApiResult<GetNotesResult>>(this.apiUrl + "Note/GetNotes", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchNotesAsync", "error.", response.data.error);
          } else {
            if (response.data.result.notes && this.stockPositions !== null) {
              response.data.result.notes.forEach((x) => {
                if (x.stockPositionId !== null && this.stockPositions !== null) {
                  const clientStockPosition = this.stockPositions.find((st) => st.id === x.stockPositionId);
                  if (clientStockPosition === undefined) {
                    this.addErrorWithDetails("fetchNotesAsync", "Cannot find StockPosition.", "StockPositionId :" + x.stockPositionId);
                  } else {
                    clientStockPosition.addNote(x);
                  }
                }
              });
            }
            if (response.data.result.notes && this.fundPositions !== null) {
              response.data.result.notes.forEach((x) => {
                if (x.fundPositionId !== null && this.fundPositions !== null) {
                  const clientFundPosition = this.fundPositions.find((st) => st.id === x.fundPositionId);
                  if (clientFundPosition === undefined) {
                    this.addErrorWithDetails("fetchNotesAsync", "Cannot find FundPosition.", "fundPositionId :" + x.fundPositionId);
                  } else {
                    clientFundPosition.addNote(x);
                  }
                }
              });
            }
            if (response.data.result.notes && this.coinPositions !== null) {
              response.data.result.notes.forEach((x) => {
                if (x.coinPositionId !== null && this.coinPositions !== null) {
                  const clientCoinPosition = this.coinPositions.find((st) => st.id === x.coinPositionId);
                  if (clientCoinPosition === undefined) {
                    this.addErrorWithDetails("fetchNotesAsync", "Cannot find CoinPosition.", "CoinPositionId :" + x.coinPositionId);
                  } else {
                    clientCoinPosition.addNote(x);
                  }
                }
              });
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchNotesAsync", error);
      }
    },
    async getAndUpdateAccountAmount(id: number) {
      this.addInfo("getAndUpdateAccountAmount", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = { accountId: id } as GetAccountsRequest;
        const response = await axios.post<ApiResult<GetAccountsResult>>(this.apiUrl + "Account/GetAccounts", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
          } else {
            if (response.data.result.items && response.data.result.items.length === 1) {
              const serverItem = response.data.result.items[0];

              if (this.accounts) {
                const clientItem = this.accounts.find((x) => x.id === serverItem.id);
                if (clientItem === undefined) {
                  this.addError("getAndUpdateAccountAmount", "Cannot find account.");
                } else {
                  clientItem.amount = serverItem.amount;
                }
              }
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("getAndUpdateAccountAmount", error);
      }
    },
    async fetchStockTickersOneTimeAsync() {
      this.addInfo("fetchStockTickersOneTimeAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetStockTickersRequest;
        const response = await axios.post<ApiResult<GetStockTickersResult>>(this.apiUrl + "StockTicker/GetStockTickers", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchStockPricesAsync", "error.", response.data.error);
          } else {
            this.stockTickers = response.data.result.items.map((x) => new MyStockTicker(x.id, x.ticker, x.name, x.typeOfEquity, x.isTheMarketOpen)) as MyStockTicker[];
            this.addInfo("fetchStockTickersOneTimeAsync", "Getted " + this.stockTickers.length + " items.");
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchStockTickersOneTimeAsync", error);
      }
    },
    async fetchFundTickersOneTimeAsync() {
      this.addInfo("fetchFundTickersOneTimeAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetFundTickersRequest;
        const response = await axios.post<ApiResult<GetFundTickersResult>>(this.apiUrl + "FundTicker/GetFundTickers", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchFundTickersOneTimeAsync", "error.", response.data.error);
          } else {
            this.fundTickers = response.data.result.items.map((x) => new MyFundTicker(x.id, x.ticker, x.name, x.isTheMarketOpen)) as MyFundTicker[];
            this.addInfo("fetchFundTickersOneTimeAsync", "Getted " + this.fundTickers.length + " items.");
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchFundTickersOneTimeAsync", error);
      }
    },
    async fetchCoinTickersOneTimeAsync() {
      this.addInfo("fetchCoinTickersOneTimeAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetCoinTickersRequest;
        const response = await axios.post<ApiResult<GetCoinTickersResult>>(this.apiUrl + "CoinTicker/GetCoinTickers", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchCoinPricesAsync", "error.", response.data.error);
          } else {
            this.coinTickers = response.data.result.items.map((x) => new MyCoinTicker(x.id, x.ticker, x.name)) as MyCoinTicker[];
            this.addInfo("fetchCoinTickersOneTimeAsync", "Getted " + this.coinTickers.length + " items.");
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchCoinTickersOneTimeAsync", error);
      }
    },
    async fetchStockTickers() {
      this.addInfo("fetchStockTickers", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetStockTickersRequest;
        const response = await axios.post<ApiResult<GetStockTickersResult>>(this.apiUrl + "StockTicker/GetStockTickers", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchStockTickers", "error.", response.data.error);
          } else {
            const serverStockTickers = response.data.result.items.map((x) => new MyStockTicker(x.id, x.ticker, x.name, x.typeOfEquity, x.isTheMarketOpen)) as MyStockTicker[];

            this.addInfo("fetchStockTickers", "Getted " + serverStockTickers.length + " items.");

            const diff = arrayDifferenceAndIntersection(serverStockTickers, this.stockTickers, "id");

            if (diff) {
              if (diff.inFirstNotInSecond && diff.inFirstNotInSecond.length > 0) {
                // sunucuda fazla item var
                this.addInfo("fetchStockTickers", "Server has extra items. " + diff.inFirstNotInSecond.map((x) => x.ticker).join(", "));
                diff.inFirstNotInSecond.forEach((x) => {
                  this.stockTickers.push(x);
                });
              }
              if (diff.inSecondNotInFirst && diff.inSecondNotInFirst.length > 0) {
                this.addInfo("fetchStockTickers", "Client has extra items. " + diff.inSecondNotInFirst.map((x) => x.ticker).join(", "));
                // client'ta fazla item var
              }
              if (diff.inBoth) {
                diff.inBoth.forEach((x) => {
                  const clientItem = this.stockTickers.find((sp) => sp.id == x.id);
                  const serverItem = serverStockTickers.find((sp) => sp.id == x.id);
                  if (clientItem && serverItem) {
                    clientItem.currentPrice = serverItem.currentPrice;
                    clientItem.currentPriceDate = serverItem.currentPriceDate;
                    clientItem.currentPriceGettedDate = new Date();
                    clientItem.currentPriceSource = serverItem.currentPriceSource;
                    clientItem.isMarketOpen = serverItem.isMarketOpen;
                    clientItem.lastClosePrice = serverItem.lastClosePrice;
                    clientItem.lastClosePriceDate = serverItem.lastClosePriceDate;
                    clientItem.lastClosePriceGettedDate = new Date();
                    clientItem.lastClosePriceSource = serverItem.lastClosePriceSource;
                  } else {
                    this.addError("fetchStockTickers", "Cannot find clientItem or serverItem.");
                  }
                });
              }
            } else {
              this.addInfo("fetchStockTickers", "No diff between server and client.");
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchStockTickers", error);
      }
    },
    async fetchFundTickers() {
      this.addInfo("fetchFundTickers", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetFundTickersRequest;
        const response = await axios.post<ApiResult<GetFundTickersResult>>(this.apiUrl + "FundTicker/GetFundTickers", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchFundTickers", "error.", response.data.error);
          } else {
            const serverFundTickers = response.data.result.items.map((x) => new MyFundTicker(x.id, x.ticker, x.name, x.isTheMarketOpen)) as MyFundTicker[];

            this.addInfo("fetchFundTickers", "Getted " + serverFundTickers.length + " items.");

            const diff = arrayDifferenceAndIntersection(serverFundTickers, this.fundTickers, "id");

            if (diff) {
              if (diff.inFirstNotInSecond && diff.inFirstNotInSecond.length > 0) {
                // sunucuda fazla item var
                this.addInfo("fetchFundTickers", "Server has extra items. " + diff.inFirstNotInSecond.map((x) => x.ticker).join(", "));
                diff.inFirstNotInSecond.forEach((x) => {
                  this.fundTickers.push(x);
                });
              }
              if (diff.inSecondNotInFirst && diff.inSecondNotInFirst.length > 0) {
                this.addInfo("fetchFundTickers", "Client has extra items. " + diff.inSecondNotInFirst.map((x) => x.ticker).join(", "));
                // client'ta fazla item var
              }
              if (diff.inBoth) {
                diff.inBoth.forEach((x) => {
                  const clientItem = this.fundTickers.find((sp) => sp.id == x.id);
                  const serverItem = serverFundTickers.find((sp) => sp.id == x.id);
                  if (clientItem && serverItem) {
                    clientItem.currentPrice = serverItem.currentPrice;
                    clientItem.currentPriceDate = serverItem.currentPriceDate;
                    clientItem.currentPriceGettedDate = new Date();
                    clientItem.currentPriceSource = serverItem.currentPriceSource;
                    clientItem.isMarketOpen = serverItem.isMarketOpen;
                    clientItem.lastClosePrice = serverItem.lastClosePrice;
                    clientItem.lastClosePriceDate = serverItem.lastClosePriceDate;
                    clientItem.lastClosePriceGettedDate = new Date();
                    clientItem.lastClosePriceSource = serverItem.lastClosePriceSource;
                  } else {
                    this.addError("fetchFundTickers", "Cannot find clientItem or serverItem.");
                  }
                });
              }
            } else {
              this.addInfo("fetchFundTickers", "No diff between server and client.");
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchFundTickers", error);
      }
    },
    async fetchCoinTickers() {
      this.addInfo("fetchCoinTickers", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetCoinTickersRequest;
        const response = await axios.post<ApiResult<GetCoinTickersResult>>(this.apiUrl + "CoinTicker/GetCoinTickers", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchCoinTickers", "error.", response.data.error);
          } else {
            const serverCoinTickers = response.data.result.items.map((x) => new MyCoinTicker(x.id, x.ticker, x.name)) as MyCoinTicker[];

            this.addInfo("fetchCoinTickers", "Getted " + serverCoinTickers.length + " items.");

            const diff = arrayDifferenceAndIntersection(serverCoinTickers, this.coinTickers, "id");

            if (diff) {
              if (diff.inFirstNotInSecond && diff.inFirstNotInSecond.length > 0) {
                // sunucuda fazla item var
                this.addInfo("fetchCoinTickers", "Server has extra items. " + diff.inFirstNotInSecond.map((x) => x.ticker).join(", "));
                diff.inFirstNotInSecond.forEach((x) => {
                  this.coinTickers.push(x);
                });
              }
              if (diff.inSecondNotInFirst && diff.inSecondNotInFirst.length > 0) {
                this.addInfo("fetchCoinTickers", "Client has extra items. " + diff.inSecondNotInFirst.map((x) => x.ticker).join(", "));
                // client'ta fazla item var
              }
              if (diff.inBoth) {
                diff.inBoth.forEach((x) => {
                  const clientItem = this.coinTickers.find((sp) => sp.id == x.id);
                  const serverItem = serverCoinTickers.find((sp) => sp.id == x.id);
                  if (clientItem && serverItem) {
                    clientItem.currentPrice = serverItem.currentPrice;
                    clientItem.currentPriceDate = serverItem.currentPriceDate;
                    clientItem.currentPriceGettedDate = new Date();
                    clientItem.currentPriceSource = serverItem.currentPriceSource;
                    clientItem.lastClosePrice = serverItem.lastClosePrice;
                    clientItem.lastClosePriceDate = serverItem.lastClosePriceDate;
                    clientItem.lastClosePriceGettedDate = new Date();
                    clientItem.lastClosePriceSource = serverItem.lastClosePriceSource;
                  } else {
                    this.addError("fetchCoinTickers", "Cannot find clientItem or serverItem.");
                  }
                });
              }
            } else {
              this.addInfo("fetchCoinTickers", "No diff between server and client.");
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchCoinTickers", error);
      }
    },

    syncItems<T>(clientItems: T[], serverItems: T[], key: keyof T): { updatedClientItems: T[]; addedIds: any[]; removedIds: any[] } {
      const addedIds: any[] = [];
      const removedIds: any[] = [];

      // Create a Map of the existing client items by key for easy lookup
      const clientItemMap = new Map<any, T>(clientItems.map((item) => [item[key], item]));

      // Create a new array for the updated client items
      const updatedClientItems: T[] = [];

      // Update or add items based on the server response
      for (const serverItem of serverItems) {
        const clientItem = clientItemMap.get(serverItem[key]);
        if (clientItem) {
          // Update the item if it already exists in the client
          Object.assign(clientItem, serverItem);

          // Add the updated item to the new array
          updatedClientItems.push(clientItem);

          // Remove from the map to track which items have been processed
          clientItemMap.delete(serverItem[key]);
        } else {
          // Add the new item to the array if it doesn't exist in the client
          updatedClientItems.push(serverItem);
          addedIds.push(serverItem[key]); // Track added item ID
        }
      }

      // Remove items that are in the client but not in the server
      for (const [id] of clientItemMap) {
        removedIds.push(id); // Track removed item ID
      }

      // Update the clientItems array
      clientItems.length = 0; // Clear the original array
      clientItems.push(...updatedClientItems); // Push the updated items into it

      return { updatedClientItems, addedIds, removedIds };
    },

    async fetchStockPositionsOneTimeAsync() {
      this.addInfo("fetchStockPositionsOneTimeAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetStockPositionsRequest;
        const response = await axios.post<ApiResult<GetStockPositionsResult>>(this.apiUrl + "StockPosition/GetStockPositions", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchStockPositionsOneTimeAsync", "error.", response.data.error);
          } else {
            this.stockPositions = response.data.result.items.map((x) => new MyStockPosition(x.id, x.accountId, x.stockTickerId, x.count, x.averagePrice, x.averagePriceInUsd)) as MyStockPosition[];
            this.addInfo("fetchStockPositionsOneTimeAsync", "Getted " + this.stockPositions.length + " items.");
            this.stockPositions.forEach((sp) => {
              const stockTicker = this.stockTickers.find((x) => x.id === sp.stockTickerId);
              if (stockTicker === undefined) {
                this.addError("fetchStockPositionsOneTimeAsync", "Cannot find stockTicker.");
              } else {
                sp.stockTicker = stockTicker;
              }
            });

            // response.data.result.items.forEach((sh) => {
            //   const account = this.accounts.find((account) => account.id == sh.accountId);
            //   if (account !== undefined) {
            //     account.addOrUpdateStockPosition(new MyStockPosition(sh.id, sh.accountId, sh.stockTickerId, sh.count, sh.averagePrice));
            //   }
            // });
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchStockPositionsOneTimeAsync", error);
      }
    },
    async fetchFundPositionsOneTimeAsync() {
      this.addInfo("fetchFundPositionsOneTimeAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetFundPositionsRequest;
        const response = await axios.post<ApiResult<GetFundPositionsResult>>(this.apiUrl + "FundPosition/GetFundPositions", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchFundPositionsOneTimeAsync", "error.", response.data.error);
          } else {
            this.fundPositions = response.data.result.items.map((x) => new MyFundPosition(x.id, x.accountId, x.fundTickerId, x.count, x.averagePrice, x.averagePriceInUsd)) as MyFundPosition[];
            this.addInfo("fetchFundPositionsOneTimeAsync", "Getted " + this.fundPositions.length + " items.");
            this.fundPositions.forEach((sp) => {
              const fundTicker = this.fundTickers.find((x) => x.id === sp.fundTickerId);
              if (fundTicker === undefined) {
                this.addError("fetchFundPositionsOneTimeAsync", "Cannot find fundTicker.");
              } else {
                sp.fundTicker = fundTicker;
              }
            });

            // response.data.result.items.forEach((sh) => {
            //   const account = this.accounts.find((account) => account.id == sh.accountId);
            //   if (account !== undefined) {
            //     account.addOrUpdateStockPosition(new MyStockPosition(sh.id, sh.accountId, sh.stockTickerId, sh.count, sh.averagePrice));
            //   }
            // });
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchFundPositionsOneTimeAsync", error);
      }
    },
    async fetchCoinPositionsOneTimeAsync() {
      this.addInfo("fetchCoinPositionsOneTimeAsync", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetCoinPositionsRequest;
        const response = await axios.post<ApiResult<GetCoinPositionsResult>>(this.apiUrl + "CoinPosition/GetCoinPositions", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchCoinPositionsOneTimeAsync", "error.", response.data.error);
          } else {
            this.coinPositions = response.data.result.items.map((x) => new MyCoinPosition(x.id, x.accountId, x.coinTickerId, x.count, x.averagePrice, x.averagePriceInUsd)) as MyCoinPosition[];
            this.addInfo("fetchCoinPositionsOneTimeAsync", "Getted " + this.coinPositions.length + " items.");
            this.coinPositions.forEach((sp) => {
              const coinTicker = this.coinTickers.find((x) => x.id === sp.coinTickerId);
              if (coinTicker === undefined) {
                this.addError("fetchCoinPositionsOneTimeAsync", "Cannot find coinTicker.");
              } else {
                sp.coinTicker = coinTicker;
              }
            });

            // response.data.result.items.forEach((sh) => {
            //   const account = this.accounts.find((account) => account.id == sh.accountId);
            //   if (account !== undefined) {
            //     account.addOrUpdateStockPosition(new MyStockPosition(sh.id, sh.accountId, sh.stockTickerId, sh.count, sh.averagePrice));
            //   }
            // });
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchCoinPositionsOneTimeAsync", error);
      }
    },

    async fetchStockPositions() {
      this.addInfo("fetchStockPositions", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetStockPositionsRequest;
        const response = await axios.post<ApiResult<GetStockPositionsResult>>(this.apiUrl + "StockPosition/GetStockPositions", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchStockPositions", "error.", response.data.error);
          } else {
            const serverStockPositions = response.data.result.items.map((x) => new MyStockPosition(x.id, x.accountId, x.stockTickerId, x.count, x.averagePrice, x.averagePriceInUsd)) as MyStockPosition[];

            this.addInfo("fetchStockPositions", "Getted " + serverStockPositions.length + " items.");

            const diff = arrayDifferenceAndIntersection(serverStockPositions, this.stockPositions, "id");

            if (diff) {
              if (diff.inFirstNotInSecond && diff.inFirstNotInSecond.length > 0) {
                // sunucuda fazla item var
                this.addInfo("fetchStockPositions", "Server has extra items. " + diff.inFirstNotInSecond.map((x) => x.id).join(", "));
                diff.inFirstNotInSecond.forEach((sp) => {
                  const stockTicker = this.stockTickers.find((x) => x.id === sp.stockTickerId);
                  if (stockTicker === undefined) {
                    this.addError("fetchStockPositions", "Cannot find stockTicker.");
                  } else {
                    sp.stockTicker = stockTicker;
                  }
                  const account = this.accounts?.find((x) => x.id === sp.accountId);
                  if (account === undefined) {
                    this.addError("fetchStockPositions", "Cannot find account.");
                  } else {
                    sp.account = account;
                    if (account.stockPositions === undefined || account.stockPositions === null) {
                      account.stockPositions = [];
                    }
                    account.stockPositions.push(sp);
                  }
                  this.stockPositions?.push(sp);
                });
              }
              if (diff.inSecondNotInFirst && diff.inSecondNotInFirst.length > 0) {
                this.addInfo("fetchStockPositions", "Client has extra items. " + diff.inSecondNotInFirst.map((x) => x.id).join(", "));
                diff.inSecondNotInFirst.forEach((x) => {
                  const clientItem = this.stockPositions?.find((sp) => sp.id === x.id);
                  if (clientItem) {
                    const index = this.stockPositions?.indexOf(clientItem);
                    if (index !== undefined && index >= 0) {
                      this.stockPositions?.splice(index, 1);
                    }
                  }
                });
              }
              if (diff.inBoth) {
                diff.inBoth.forEach((x) => {
                  const clientItem = this.stockPositions?.find((sp) => sp.id == x.id);
                  const serverItem = serverStockPositions.find((sp) => sp.id == x.id);
                  if (clientItem && serverItem) {
                    clientItem.amount = serverItem.amount;
                    clientItem.averagePrice = serverItem.averagePrice;
                    clientItem.averagePriceInUsd = serverItem.averagePriceInUsd;
                    clientItem.count = serverItem.count;
                  } else {
                    this.addError("fetchStockPositions", "Cannot find clientItem or serverItem.");
                  }
                });
              }
            } else {
              this.addInfo("fetchStockPositions", "No diff between server and client.");
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchStockPositionsOneTimeAsync", error);
      }
    },

    async fetchFundPositions() {
      this.addInfo("fetchFundPositions", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetFundPositionsRequest;
        const response = await axios.post<ApiResult<GetFundPositionsResult>>(this.apiUrl + "FundPosition/GetFundPositions", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchFundPositions", "error.", response.data.error);
          } else {
            const serverFundPositions = response.data.result.items.map((x) => new MyFundPosition(x.id, x.accountId, x.fundTickerId, x.count, x.averagePrice, x.averagePriceInUsd)) as MyFundPosition[];

            this.addInfo("fetchFundPositions", "Getted " + serverFundPositions.length + " items.");

            const diff = arrayDifferenceAndIntersection(serverFundPositions, this.fundPositions, "id");

            if (diff) {
              if (diff.inFirstNotInSecond && diff.inFirstNotInSecond.length > 0) {
                // sunucuda fazla item var
                this.addInfo("fetchFundPositions", "Server has extra items. " + diff.inFirstNotInSecond.map((x) => x.id).join(", "));
                diff.inFirstNotInSecond.forEach((sp) => {
                  const fundTicker = this.fundTickers.find((x) => x.id === sp.fundTickerId);
                  if (fundTicker === undefined) {
                    this.addError("fetchFundPositions", "Cannot find fundTicker.");
                  } else {
                    sp.fundTicker = fundTicker;
                  }
                  const account = this.accounts?.find((x) => x.id === sp.accountId);
                  if (account === undefined) {
                    this.addError("fetchFundPositions", "Cannot find account.");
                  } else {
                    sp.account = account;
                    if (account.fundPositions === undefined || account.fundPositions === null) {
                      account.fundPositions = [];
                    }
                    account.fundPositions.push(sp);
                  }
                  this.fundPositions?.push(sp);
                });
              }
              if (diff.inSecondNotInFirst && diff.inSecondNotInFirst.length > 0) {
                this.addInfo("fetchFundPositions", "Client has extra items. " + diff.inSecondNotInFirst.map((x) => x.id).join(", "));
                diff.inSecondNotInFirst.forEach((x) => {
                  const clientItem = this.fundPositions?.find((sp) => sp.id === x.id);
                  if (clientItem) {
                    const index = this.fundPositions?.indexOf(clientItem);
                    if (index !== undefined && index >= 0) {
                      this.fundPositions?.splice(index, 1);
                    }
                  }
                });
              }
              if (diff.inBoth) {
                diff.inBoth.forEach((x) => {
                  const clientItem = this.fundPositions?.find((sp) => sp.id == x.id);
                  const serverItem = serverFundPositions.find((sp) => sp.id == x.id);
                  if (clientItem && serverItem) {
                    clientItem.amount = serverItem.amount;
                    clientItem.averagePrice = serverItem.averagePrice;
                    clientItem.averagePriceInUsd = serverItem.averagePriceInUsd;
                    clientItem.count = serverItem.count;
                  } else {
                    this.addError("fetchFundPositions", "Cannot find clientItem or serverItem.");
                  }
                });
              }
            } else {
              this.addInfo("fetchFundPositions", "No diff between server and client.");
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchFundPositionsOneTimeAsync", error);
      }
    },

    async fetchCoinPositions() {
      this.addInfo("fetchCoinPositions", "Start.");
      try {
        const requestConfig = {
          headers: {
            Authorization: "Bearer " + useAuthStore().signUpResult?.jwtToken,
          },
        };
        const request = {} as GetCoinPositionsRequest;
        const response = await axios.post<ApiResult<GetCoinPositionsResult>>(this.apiUrl + "CoinPosition/GetCoinPositions", request, requestConfig);
        if (response) {
          if (response.data && response.data.error) {
            this.addErrorWithDetails("fetchCoinPositions", "error.", response.data.error);
          } else {
            const serverCoinPositions = response.data.result.items.map((x) => new MyCoinPosition(x.id, x.accountId, x.coinTickerId, x.count, x.averagePrice, x.averagePriceInUsd)) as MyCoinPosition[];

            this.addInfo("fetchCoinPositions", "Getted " + serverCoinPositions.length + " items.");

            const diff = arrayDifferenceAndIntersection(serverCoinPositions, this.coinPositions, "id");

            if (diff) {
              if (diff.inFirstNotInSecond && diff.inFirstNotInSecond.length > 0) {
                // sunucuda fazla item var
                this.addInfo("fetchCoinPositions", "Server has extra items. " + diff.inFirstNotInSecond.map((x) => x.id).join(", "));
                diff.inFirstNotInSecond.forEach((sp) => {
                  const coinTicker = this.coinTickers.find((x) => x.id === sp.coinTickerId);
                  if (coinTicker === undefined) {
                    this.addError("fetchCoinPositions", "Cannot find coinTicker.");
                  } else {
                    sp.coinTicker = coinTicker;
                  }
                  const account = this.accounts?.find((x) => x.id === sp.accountId);
                  if (account === undefined) {
                    this.addError("fetchCoinPositions", "Cannot find account.");
                  } else {
                    sp.account = account;
                    if (account.coinPositions === undefined || account.coinPositions === null) {
                      account.coinPositions = [];
                    }
                    account.coinPositions.push(sp);
                  }
                  this.coinPositions?.push(sp);
                });
              }
              if (diff.inSecondNotInFirst && diff.inSecondNotInFirst.length > 0) {
                this.addInfo("fetchCoinPositions", "Client has extra items. " + diff.inSecondNotInFirst.map((x) => x.id).join(", "));
                diff.inSecondNotInFirst.forEach((x) => {
                  const clientItem = this.coinPositions?.find((sp) => sp.id === x.id);
                  if (clientItem) {
                    const index = this.coinPositions?.indexOf(clientItem);
                    if (index !== undefined && index >= 0) {
                      this.coinPositions?.splice(index, 1);
                    }
                  }
                });
              }
              if (diff.inBoth) {
                diff.inBoth.forEach((x) => {
                  const clientItem = this.coinPositions?.find((sp) => sp.id == x.id);
                  const serverItem = serverCoinPositions.find((sp) => sp.id == x.id);
                  if (clientItem && serverItem) {
                    clientItem.amount = serverItem.amount;
                    clientItem.averagePrice = serverItem.averagePrice;
                    clientItem.averagePriceInUsd = serverItem.averagePriceInUsd;
                    clientItem.count = serverItem.count;
                  } else {
                    this.addError("fetchCoinPositions", "Cannot find clientItem or serverItem.");
                  }
                });
              }
            } else {
              this.addInfo("fetchCoinPositions", "No diff between server and client.");
            }
          }
        }
      } catch (error) {
        this.addExceptionWithDetails("fetchCoinPositionsOneTimeAsync", error);
      }
    },
    updateAccountsForNewExchangeRate() {
      this.addInfo("updateAccountsForNewExchangeRate", "Start.");
      if (this.accounts && this.accounts.length > 0 && this.exchangeRates && this.exchangeRates.length > 0) {
        this.addInfo("updateAccountsForNewExchangeRate", "Account count: " + this.accounts.length);
        this.accounts.forEach((account) => {
          if (account.currency !== "USD") {
            const exchangeRate = this.exchangeRates.find((x) => x.symbol === "USD" + account.currency);
            if (exchangeRate) {
              account.exchangeRate = exchangeRate;
            } else {
              this.logs.push({ type: "Error", message: `Cannot find exchange rate for account: ${account.name} and curreny: ${account.currency}.`, date: new Date() } as ILog);
            }
          }
        });
      } else {
        this.addError("updateAccountsForNewExchangeRate", "No records.");
      }
    },
    updateStockPrice(ticker: string, newPrice: number, newPriceDate: string, newPriceSource: string) {
      if (this.stockTickers === undefined || this.stockTickers.length === 0) {
        this.addError("updateStockPrice", "StockTickers as undefined");
        return;
      }
      const stockTicker = this.stockTickers.find((x) => x.ticker === ticker);
      if (stockTicker === undefined) {
        this.addError("updateStockPrice", "Cannot find StockTicker " + ticker);
      } else {
        this.addInfo("updateStockPrice", "StockTicker price update WS. Ticker: " + ticker);
        stockTicker.currentPrice = newPrice;
        stockTicker.currentPriceDate = newPriceDate;
        stockTicker.currentPriceSource = newPriceSource;
        stockTicker.currentPriceGettedDate = new Date();
      }

      // if (this.stockTickers === undefined) {
      //   // TODO log error
      // } else {
      // const stockTicker = this.stockTickers.find((x) => x.ticker === ticker);
      // if (stockTicker === undefined) {
      //   // TODO log error
      // } else {
      // stockTicker.currentPrice = newPrice;
      // stockTicker.currentPriceDate = newPriceDate;
      // stockTicker.currentPriceSource = newPriceSource;
      // stockTicker.currentPriceGettedDate = new Date();
      // if (this.accounts === undefined) {
      //   // TODO log error
      // } else {
      //   this.accounts?.forEach((a) => {
      //     if (a.stockPositions !== undefined && a.stockPositions.length > 0) {
      //       a.stockPositions.forEach((sp) => {
      //         if (sp.stockTicker && sp.stockTicker.ticker === ticker) {
      //           sp.setStockTickerPrice(newPrice);
      //           // sp.stockTicker.currentPrice = newPrice;
      //           // sp.stockTicker.currentPriceDate = newPriceDate;
      //           // sp.stockTicker.currentPriceSource = newPriceSource;
      //           // sp.stockTicker.currentPriceGettedDate = new Date();
      //         }
      //       });
      //     }
      //   });
      // }
      // }
      // }
    },
    updateFundPrice(ticker: string, newPrice: number, newPriceDate: string, newPriceSource: string) {
      if (this.fundTickers === undefined || this.fundTickers.length === 0) {
        this.addError("updateFundPrice", "FundTickers as undefined");
        return;
      }
      const fundTicker = this.fundTickers.find((x) => x.ticker === ticker);
      if (fundTicker === undefined) {
        this.addError("updateFundPrice", "Cannot find FundTicker " + ticker);
      } else {
        this.addInfo("updateFundPrice", "FundTicker price update WS. Ticker: " + ticker);
        fundTicker.currentPrice = newPrice;
        fundTicker.currentPriceDate = newPriceDate;
        fundTicker.currentPriceSource = newPriceSource;
        fundTicker.currentPriceGettedDate = new Date();
      }

      // if (this.stockTickers === undefined) {
      //   // TODO log error
      // } else {
      // const stockTicker = this.stockTickers.find((x) => x.ticker === ticker);
      // if (stockTicker === undefined) {
      //   // TODO log error
      // } else {
      // stockTicker.currentPrice = newPrice;
      // stockTicker.currentPriceDate = newPriceDate;
      // stockTicker.currentPriceSource = newPriceSource;
      // stockTicker.currentPriceGettedDate = new Date();
      // if (this.accounts === undefined) {
      //   // TODO log error
      // } else {
      //   this.accounts?.forEach((a) => {
      //     if (a.stockPositions !== undefined && a.stockPositions.length > 0) {
      //       a.stockPositions.forEach((sp) => {
      //         if (sp.stockTicker && sp.stockTicker.ticker === ticker) {
      //           sp.setStockTickerPrice(newPrice);
      //           // sp.stockTicker.currentPrice = newPrice;
      //           // sp.stockTicker.currentPriceDate = newPriceDate;
      //           // sp.stockTicker.currentPriceSource = newPriceSource;
      //           // sp.stockTicker.currentPriceGettedDate = new Date();
      //         }
      //       });
      //     }
      //   });
      // }
      // }
      // }
    },
    updateCoinPrice(ticker: string, newPrice: number, newPriceDate: string, newPriceSource: string) {
      if (this.coinTickers === undefined || this.coinTickers.length === 0) {
        this.addError("updateCoinPrice", "CoinTickers as undefined");
        return;
      }
      const coinTicker = this.coinTickers.find((x) => x.ticker === ticker);
      if (coinTicker === undefined) {
        this.addError("updateCoinPrice", "Cannot find CoinTicker " + ticker);
      } else {
        this.addInfo("updateCoinPrice", "CoinTicker price update WS. Ticker: " + ticker);
        coinTicker.currentPrice = newPrice;
        coinTicker.currentPriceDate = newPriceDate;
        coinTicker.currentPriceSource = newPriceSource;
        coinTicker.currentPriceGettedDate = new Date();
      }

      // if (this.stockTickers === undefined) {
      //   // TODO log error
      // } else {
      // const stockTicker = this.stockTickers.find((x) => x.ticker === ticker);
      // if (stockTicker === undefined) {
      //   // TODO log error
      // } else {
      // stockTicker.currentPrice = newPrice;
      // stockTicker.currentPriceDate = newPriceDate;
      // stockTicker.currentPriceSource = newPriceSource;
      // stockTicker.currentPriceGettedDate = new Date();
      // if (this.accounts === undefined) {
      //   // TODO log error
      // } else {
      //   this.accounts?.forEach((a) => {
      //     if (a.stockPositions !== undefined && a.stockPositions.length > 0) {
      //       a.stockPositions.forEach((sp) => {
      //         if (sp.stockTicker && sp.stockTicker.ticker === ticker) {
      //           sp.setStockTickerPrice(newPrice);
      //           // sp.stockTicker.currentPrice = newPrice;
      //           // sp.stockTicker.currentPriceDate = newPriceDate;
      //           // sp.stockTicker.currentPriceSource = newPriceSource;
      //           // sp.stockTicker.currentPriceGettedDate = new Date();
      //         }
      //       });
      //     }
      //   });
      // }
      // }
      // }
    },
    connectToHub() {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(this.apiUrl + "commonHub", { withCredentials: false })
        .withAutomaticReconnect()
        .build();

      connection.onreconnecting((error) => {
        this.hubState = connection.state;
      });

      connection.onreconnected((error) => {
        this.hubState = connection.state;
      });

      connection.on("ReceiveMessage", (messageType: string, message: string) => {
        if (messageType === "UPDATE STOCKHOLDING") {
          // if (this.stockHoldings) {
          //   const command = JSON.parse(message)
          //   const stockHolding = this.stockHoldings.find((x) => x.id === command.Id)
          //   if (stockHolding) {
          //     stockHolding.amount = command.Amount
          //     stockHolding.amountInUsd = command.AmountInUsd
          //   }
          // }
        } else if (messageType === "UPDATE COINHOLDING") {
          // if (this.stockHoldings) {
          //   const command = JSON.parse(message)
          //   const coinHolding = this.coinHoldings.find((x) => x.id === command.Id)
          //   if (coinHolding) {
          //     coinHolding.amount = command.Amount
          //     coinHolding.amountInUsd = command.AmountInUsd
          //   }
          // }
        } else if (messageType === "UPDATE FUNDHOLDING") {
          // if (this.fundHoldings) {
          //   const command = JSON.parse(message)
          //   const fundHolding = this.fundHoldings.find((x) => x.id === command.Id)
          //   if (fundHolding) {
          //     fundHolding.amount = command.Amount
          //     fundHolding.amountInUsd = command.AmountInUsd
          //   }
          // }
        } else if (messageType === "UPDATE STOCKPRICE") {
          // if (this.stockTickers) {
          const command: UpdateStockPriceCommand = JSON.parse(message);

          this.updateStockPrice(command.ticker, command.price, command.priceDate, command.source);
          // const stockTicker = this.stockTickers.find((x) => x.ticker === command.ticker);

          // if (stockTicker) {
          //   try {
          //     stockTicker.currentPrice = command.price;
          //     stockTicker.currentPriceDate = command.priceDate;
          //     stockTicker.currentPriceSource = command.source;
          //     stockTicker.currentPriceGettedDate = new Date();

          //     // const stockHolding = this.stockHoldings.find(
          //     //   (x) => x.stockTicker.ticker === command.symbol
          //     // )
          //     // if (stockHolding && command.currentPrice) {
          //     //   stockHolding.amountInUsd = command.currentPrice * stockHolding.count
          //     //   // stockHolding.amountInUsd = command.AmountInUsd
          //     // }

          //     // if (command.currentPrice !== null) this.updateStockPrice(stockTicker.ticker, command.currentPrice);
          //   } catch (e) {
          //     //
          //   }
          // } else {
          //   /// olmamalı
          // }
          // }
        } else if (messageType === "UPDATE COINPRICE") {
          // if (this.stockTickers) {
          const command: UpdateCoinPriceCommand = JSON.parse(message);

          this.updateCoinPrice(command.ticker, command.price, command.priceDate, command.source);
          // const stockTicker = this.stockTickers.find((x) => x.ticker === command.ticker);

          // if (stockTicker) {
          //   try {
          //     stockTicker.currentPrice = command.price;
          //     stockTicker.currentPriceDate = command.priceDate;
          //     stockTicker.currentPriceSource = command.source;
          //     stockTicker.currentPriceGettedDate = new Date();

          //     // const stockHolding = this.stockHoldings.find(
          //     //   (x) => x.stockTicker.ticker === command.symbol
          //     // )
          //     // if (stockHolding && command.currentPrice) {
          //     //   stockHolding.amountInUsd = command.currentPrice * stockHolding.count
          //     //   // stockHolding.amountInUsd = command.AmountInUsd
          //     // }

          //     // if (command.currentPrice !== null) this.updateStockPrice(stockTicker.ticker, command.currentPrice);
          //   } catch (e) {
          //     //
          //   }
          // } else {
          //   /// olmamalı
          // }
          // }
        } else if (messageType === "UPDATE FUNDPRICE") {
          // if (this.stockTickers) {
          const command: UpdateFundPriceCommand = JSON.parse(message);

          this.updateFundPrice(command.ticker, command.price, command.priceDate, command.source);
          // const stockTicker = this.stockTickers.find((x) => x.ticker === command.ticker);

          // if (stockTicker) {
          //   try {
          //     stockTicker.currentPrice = command.price;
          //     stockTicker.currentPriceDate = command.priceDate;
          //     stockTicker.currentPriceSource = command.source;
          //     stockTicker.currentPriceGettedDate = new Date();

          //     // const stockHolding = this.stockHoldings.find(
          //     //   (x) => x.stockTicker.ticker === command.symbol
          //     // )
          //     // if (stockHolding && command.currentPrice) {
          //     //   stockHolding.amountInUsd = command.currentPrice * stockHolding.count
          //     //   // stockHolding.amountInUsd = command.AmountInUsd
          //     // }

          //     // if (command.currentPrice !== null) this.updateStockPrice(stockTicker.ticker, command.currentPrice);
          //   } catch (e) {
          //     //
          //   }
          // } else {
          //   /// olmamalı
          // }
          // }
        } else if (messageType === "USERMESSAGEINFORMATION") {
          //
        }
      });

      connection
        .start()
        .then(() => {
          this.hubState = connection.state;
        })
        .catch((err) => {
          console.log("Hub error:", err);
          this.hubState = connection.state;
        });

      return true;
    },
    logoff() {
      this.notifications = [];
      this.stockTickers = [];
      this.fundTickers = [];
      this.coinTickers = [];
      this.stockPositions = [];
      this.coinPositions = [];
      this.fundPositions = [];
      this.accounts = [];
      this.logs = [];
      this.accounts = [];
      this.edittingAccount = undefined;
    },
  },
});
