<script setup lang="ts">
import { useOnboardingStore } from '@/stores/onboarding';
import type { IOnboardingStep } from '@/types/IAssetType';
import { ref, watch } from 'vue';
import { RocketLaunchIcon, FaceSmileIcon } from '@heroicons/vue/24/outline'
import { useAuthStore } from '@/stores/auth';
import { useCounterStore } from '@/stores/counter';
const authStore = useAuthStore()
const store = useCounterStore()
const onboardingStore = useOnboardingStore()

const myStep = ref(onboardingStore.steps.find(x => x.tag === 'Final') as IOnboardingStep)

watch(
    () => [onboardingStore.stepsData.addStockAccountComponent.accountName],
    () => {
        myStep.value.canGoNext = (onboardingStore.stepsData.addStockAccountComponent.accountName !== undefined && onboardingStore.stepsData.addStockAccountComponent.accountName !== null && onboardingStore.stepsData.addStockAccountComponent.accountName.length >= 2)
    }

)
const isLoading = ref(false);

async function setupMyAccount() {
    isLoading.value = true;
    try {
        await onboardingStore.setupMyAccount();
    } finally {
        isLoading.value = false;
    }
}
async function closeMe() {

    onboardingStore.show = false;
    await store.start()
}
</script>

<template>

    <div class=" bg-slate-300 -mx-4 -mt-5 sm:-m-6  p-4 sm:px-6 sm:py-4 ">
        <h3 class="text-base font-semibold leading-6 text-gray-900">We are ready</h3>
        <p class="mt-0 max-w-4xl text-sm text-gray-500">Click setup my account button.</p>
    </div>



    <div class="mt-10 mb-6 mx-auto flex flex-col items-center justify-items-center">

        <button type="button" v-if="onboardingStore.setupDone === false" @click="setupMyAccount()" :disabled="isLoading" :class="[
            'inline-flex flex-col items-center rounded-md px-3.5 pt-4 pb-4 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
            isLoading
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
        ]">
            <div class="size-16 p-2  mb-2 bg-gray-800/10 rounded-full ">
                <RocketLaunchIcon class=" text-indigo-50/80 " aria-hidden="true" />
            </div>
            {{ isLoading ? 'Setting up...' : 'Setup My Account' }}
        </button>

        <button type="button" v-if="onboardingStore.setupDone === true" @click="closeMe()"
            :class="'inline-flex flex-col items-center rounded-md px-3.5 pt-4 pb-4 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'">
            <div class="size-16 p-2  mb-2 bg-gray-800/10 rounded-full ">
                <FaceSmileIcon class=" text-indigo-50/80 " aria-hidden="true" />
            </div>
            Start using Winner!
        </button>

        <div class="mt-4 text-sm text-gray-800" v-if="onboardingStore.setupDone === false">{{ onboardingStore.progressSentence }}</div>
        <div class="mt-4 text-sm text-gray-800" v-if="onboardingStore.setupDone === true">Your account is ready.</div>


    </div>
</template>