import { nanoid } from "nanoid";

export class NotificationItem {
  id: string;
  title: string;
  text: string;
  subItems: string[] | undefined;
  type: NotificationItemType;

  constructor(title: string, text: string, type: NotificationItemType, subItems: string[] | undefined = undefined) {
    this.id = nanoid();
    this.title = title;
    this.text = text;
    this.type = type;
    this.subItems = subItems;
  }
}

export enum NotificationItemType {
  Success = 1,
  Warning,
  Error,
  Info,
}
