import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AuthView from "../views/AuthView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "auth",
      component: AuthView,
      children: [
        {
          path: "",
          name: "login",
          component: () => import("../components/auth/LoginComponent.vue"),
        },
        {
          path: "signup",
          name: "signup",
          component: () => import("../components/auth/SignUpComponent.vue"),
        },
      ],
    },
    {
      path: "/home",
      name: "home",
      component: HomeView,
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: () => import("../components/dashboard/DashboardLayout.vue"),
        },
        {
          path: "accounts",
          name: "accounts",
          redirect: "/home/accounts/list",
          component: () => import("../components/account/AccountsLayout.vue"),
          children: [
            {
              path: "list",
              name: "accountslist",
              component: () => import("../components/account/ListAccountsComponent.vue"),
            },
            {
              path: "transfers/:id?",
              name: "transfers",
              component: () => import("../components/account/ListTransfersComponent.vue"),
            },
          ],
        },
        {
          path: "stocks",
          name: "stocks",
          redirect: "/home/stocks/list",
          component: () => import("../components/stock/StocksLayout.vue"),
          children: [
            {
              path: "list/:id?",
              name: "stockslist",
              component: () => import("../components/stock/ListStockPositionsComponent.vue"),
            },
            {
              path: "transactions",
              name: "stockstransactions",
              component: () => import("../components/stock/ListStockTransactionsComponent.vue"),
            },
            // {
            //   path: "test",
            //   name: "stockstest",
            //   component: () => import("../components/stock/GridTest.vue"),
            // },
          ],
        },
        {
          path: "funds",
          name: "funds",
          redirect: "/home/funds/list",
          component: () => import("../components/fund/FundsLayout.vue"),
          children: [
            {
              path: "list/:id?",
              name: "fundslist",
              component: () => import("../components/fund/ListFundPositionsComponent.vue"),
            },
            {
              path: "transactions",
              name: "fundstransactions",
              component: () => import("../components/fund/ListFundTransactionsComponent.vue"),
            },
            // {
            //   path: "test",
            //   name: "stockstest",
            //   component: () => import("../components/stock/GridTest.vue"),
            // },
          ],
        },
        {
          path: "coins",
          name: "coins",
          redirect: "/home/coins/list",
          component: () => import("../components/coin/CoinsLayout.vue"),
          children: [
            {
              path: "list/:id?",
              name: "coinslist",
              component: () => import("../components/coin/ListCoinPositionsComponent.vue"),
            },
            {
              path: "transactions",
              name: "coinstransactions",
              component: () => import("../components/coin/ListCoinTransactionsComponent.vue"),
            },
            // {
            //   path: "test",
            //   name: "stockstest",
            //   component: () => import("../components/stock/GridTest.vue"),
            // },
          ],
        },
        {
          path: "snapshots",
          name: "snapshots",
          redirect: "/home/snapshots/list",
          component: () => import("../components/snapshot/SnapshotsLayout.vue"),
          children: [
            {
              path: "list",
              name: "snapshotslist",
              component: () => import("../components/snapshot/ListSnapshotsComponent.vue"),
            },
            {
              path: "view/:id?",
              name: "snapshotsview",
              component: () => import("../components/snapshot/ViewSnapshotComponent.vue"),
            },
            {
              path: "compare/:id1/:id2",
              name: "snapshotscompare",
              component: () => import("../components/snapshot/CompareSnapshotsComponent.vue"),
            },
            {
              path: "create",
              name: "snapshotscreate",
              component: () => import("../components/snapshot/CreateSnapshotComponent.vue"),
            },
          ],
        },
        {
          path: "logs",
          name: "logs",
          redirect: "/home/logs/listchangelog",
          component: () => import("../components/monitoring/LogsLayout.vue"),
          children: [
            {
              path: "listapplogs",
              name: "listapplogs",
              component: () => import("../components/monitoring/ListApplicationLogsComponent.vue"),
            },
            {
              path: "listchangelog",
              name: "listchangelog",
              component: () => import("../components/monitoring/ListChangeLogComponent.vue"),
            },
            {
              path: "listjobs",
              name: "listjobs",
              component: () => import("../components/monitoring/ListJobsComponent.vue"),
            },
          ],
        },
      ],
    },
  ],
});

export default router;
