import type { MyExchangeRate } from "./MyExchangeRate";
import type { MyStockPosition } from "./MyStockPosition";
import type { MyFundPosition } from "./MyFundPosition";
import type { MyCoinPosition } from "./MyCoinPosition";
import { AccountTypeEnum } from "./SharedTypes";

export class MyAccount {
  id!: number;
  amount!: number | null;
  currency!: string;
  name!: string;
  description!: string | null;
  accountType!: AccountTypeEnum;
  accountTypeMain!: AccountTypeEnum;
  cashAccountId!: number | null;
  investingAccountId!: number | null;

  exchangeRate!: MyExchangeRate | null;

  stockPositions!: MyStockPosition[];
  fundPositions!: MyFundPosition[];
  coinPositions!: MyCoinPosition[];

  constructor(id: number, amount: number | null, currency: string, name: string, description: string | null, accountType: AccountTypeEnum, cashAccountId: number | null, invsetingAccountId: number | null) {
    this.id = id;
    this.amount = amount;
    this.currency = currency;
    this.name = name;
    this.description = description;
    this.accountType = accountType;
    this.cashAccountId = cashAccountId;
    this.investingAccountId = invsetingAccountId;
    this.stockPositions = [];
    this.fundPositions = [];
    this.coinPositions = [];

    if (accountType === AccountTypeEnum.Cash) {
      this.accountTypeMain = AccountTypeEnum.Cash;
    } else if (accountType === AccountTypeEnum.CashForCoins) {
      this.accountTypeMain = AccountTypeEnum.Cash;
    } else if (accountType === AccountTypeEnum.CashForStocks) {
      this.accountTypeMain = AccountTypeEnum.Cash;
    } else {
      this.accountTypeMain = accountType;
    }
  }

  get getAmount(): number {
    if (this.amount !== null) return this.amount;
    const stocks = this.stockPositions?.reduce(function (a, stockPosition) {
      return a + (stockPosition.getCurrentAmount || 0);
    }, 0);
    const funds = this.fundPositions?.reduce(function (a, fundPoisition) {
      return a + (fundPoisition.getCurrentAmount || 0);
    }, 0);
    const coins = this.coinPositions?.reduce(function (a, coinPosition) {
      return a + (coinPosition.getCurrentAmount || 0);
    }, 0);
    return stocks + funds + coins;
  }

  get getAmountUsd(): number | null {
    if (this.currency === "USD") {
      return this.getAmount;
    } else {
      if (this.getAmount && this.exchangeRate) {
        return this.getAmount / this.exchangeRate.price;
      }
    }
    return null;
  }

  addOrUpdateStockPosition(stockPosition: MyStockPosition) {
    if (this.stockPositions === undefined) {
      this.stockPositions = [];
      this.stockPositions.push(stockPosition);
    } else if (this.stockPositions.length === 0) {
      this.stockPositions.push(stockPosition);
    } else {
      const oldItem = this.stockPositions.find((x) => x.id === stockPosition.id);
      if (oldItem === undefined) {
        this.stockPositions.push(stockPosition);
      } else {
        oldItem.amount = stockPosition.amount;
        oldItem.averagePrice = stockPosition.averagePrice;
        oldItem.count = stockPosition.count;
      }
    }
  }

  addOrUpdateFundPosition(fundPosition: MyFundPosition) {
    if (this.fundPositions === undefined) {
      this.fundPositions = [];
      this.fundPositions.push(fundPosition);
    } else if (this.fundPositions.length === 0) {
      this.fundPositions.push(fundPosition);
    } else {
      const oldItem = this.fundPositions.find((x) => x.id === fundPosition.id);
      if (oldItem === undefined) {
        this.fundPositions.push(fundPosition);
      } else {
        oldItem.amount = fundPosition.amount;
        oldItem.averagePrice = fundPosition.averagePrice;
        oldItem.count = fundPosition.count;
      }
    }
  }

  addOrUpdateCoinPosition(coinPosition: MyCoinPosition) {
    if (this.coinPositions === undefined) {
      this.coinPositions = [];
      this.coinPositions.push(coinPosition);
    } else if (this.coinPositions.length === 0) {
      this.coinPositions.push(coinPosition);
    } else {
      const oldItem = this.coinPositions.find((x) => x.id === coinPosition.id);
      if (oldItem === undefined) {
        this.coinPositions.push(coinPosition);
      } else {
        oldItem.amount = coinPosition.amount;
        oldItem.averagePrice = coinPosition.averagePrice;
        oldItem.count = coinPosition.count;
      }
    }
  }
}
