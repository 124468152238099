import type { SignUpResult } from "@/types/SharedTypes";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    signUpResult: undefined as SignUpResult | undefined,
  }),

  actions: {
    setSignUpResult(signUpResult: SignUpResult | undefined) {
      this.signUpResult = signUpResult;
    },
    logoff() {
      this.signUpResult = undefined;
    },
  },
  persist: {
    key: "my-winner-app-auth",
    storage: sessionStorage,
  },
});
