<script setup lang="ts">
import { useOnboardingStore } from '@/stores/onboarding';
import type { IOnboardingStep } from '@/types/IAssetType';
import { ref, watch } from 'vue';
import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import { useCounterStore } from '@/stores/counter';
import { RadioGroup, RadioGroupOption, DialogTitle } from '@headlessui/vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
const store = useCounterStore()
const onboardingStore = useOnboardingStore()

const myStep = ref(onboardingStore.steps.find(x => x.tag === 'AddStockAccount') as IOnboardingStep)

watch(
    () => [onboardingStore.stepsData.addStockAccountComponent.accountName],
    () => {
        myStep.value.canGoNext = (onboardingStore.stepsData.addStockAccountComponent.accountName !== undefined && onboardingStore.stepsData.addStockAccountComponent.accountName !== null && onboardingStore.stepsData.addStockAccountComponent.accountName.length >= 2)
    }

)

</script>

<template>

    <div class=" bg-slate-300 -mx-4 -mt-5 sm:-m-6  p-4 sm:px-6 sm:py-4 ">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Add an investing account</h3>
        <p class="mt-0 max-w-4xl text-sm text-gray-500">Please enter the name of your stock investing account.</p>
    </div>





    <div class="mt-10 mb-6">
        <div class="space-y-12">
            <div class="grid grid-cols-12 gap-x-4 gap-y-6 sm:grid-cols-12">

                <div class="col-span-8">
                    <label for="xtyuan" class="block text-sm font-medium leading-6 text-gray-900">Broker name</label>
                    <div class="mt-2">
                        <input v-model="onboardingStore.stepsData.addStockAccountComponent.accountName" id="xtyuan" name="xtyuan" type="text" autocomplete="off"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                    <p class="mt-1 text-sm leading-6 text-gray-600">Example: Robinhood, Midas.</p>
                </div>

                <fieldset class="col-span-12">
                    <legend class="text-sm font-semibold leading-6 text-gray-900">Currency</legend>
                    <RadioGroup v-model="onboardingStore.stepsData.addStockAccountComponent.currency" class="mt-2 grid grid-cols-3 sm:gap-y-4 gap-y-2 sm:grid-cols-3 gap-x-4">
                        <RadioGroupOption as="template" v-for="currency in store.currencies" :key="currency.code" :value="currency.code" :aria-label="currency.name"
                            :aria-description="`${currency.name} to ${currency.code}`" v-slot="{ active, checked }">
                            <div
                                :class="[active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300', 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none']">
                                <span class="flex flex-1">
                                    <span class="flex flex-col">
                                        <span class="block text-sm font-medium text-gray-900">{{ currency.code }}</span>
                                        <span class="mt-1 flex items-center text-xs text-gray-500">{{ currency.name }}</span>
                                        <!-- <span class="mt-6 text-sm font-medium text-gray-900">{{ buyOrSell.description }}</span> -->
                                    </span>
                                </span>
                                <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
                                <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']"
                                    aria-hidden="true" />
                            </div>
                        </RadioGroupOption>
                    </RadioGroup>
                </fieldset>
            </div>
        </div>
    </div>
</template>