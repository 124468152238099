import "./assets/main.css";

import Lara from "@/presets/lara";
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import App from "./App.vue";
import router from "./router";

import PrimeVue from "primevue/config";

import GoogleSignInPlugin from "vue3-google-signin";

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);

app.use(PrimeVue, {
  unstyled: true,
  pt: Lara,
});

app.use(GoogleSignInPlugin, {
  clientId: "390610802908-2p34v4vsu91ln0tie7ppq2esl5lvgghm.apps.googleusercontent.com",
});

app.mount("#app");
