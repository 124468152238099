<script setup lang="ts">
import { useOnboardingStore } from '@/stores/onboarding';
import type { IOnboardingStep } from '@/types/IAssetType';
import { computed, ref, watch } from 'vue';
import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import SelectStockComponent from '../stock/SelectStockComponent.vue';
import type { SearchStockTickerResultItem } from '@/types/SharedTypes';


const onboardingStore = useOnboardingStore()

const myStep = ref(onboardingStore.steps.find(x => x.tag === 'AddStock') as IOnboardingStep)

const showOther = computed(() => {
    return onboardingStore.stepsData.addStockComponent.selectedStock !== undefined
})

watch(
    () => [onboardingStore.stepsData.addStockComponent],
    () => {
        if (onboardingStore.stepsData.addStockComponent.selectedStock === undefined) {
            myStep.value.canGoNext = false;
            return;
        }
        if (onboardingStore.stepsData.addStockComponent.averagePrice === null || onboardingStore.stepsData.addStockComponent.averagePrice === undefined || onboardingStore.stepsData.addStockComponent.averagePrice === 0) {
            myStep.value.canGoNext = false;
            return;
        }
        if (onboardingStore.stepsData.addStockComponent.count === null || onboardingStore.stepsData.addStockComponent.count === undefined || onboardingStore.stepsData.addStockComponent.count === 0) {
            myStep.value.canGoNext = false;
            return;
        }
        if (onboardingStore.stepsData.addStockComponent.date === null || onboardingStore.stepsData.addStockComponent.date === undefined || onboardingStore.stepsData.addStockComponent.date === "") {
            myStep.value.canGoNext = false;
            return;
        }
        myStep.value.canGoNext = true;
    },
    { deep: true }
);

</script>

<template>


    <div class=" bg-slate-300 -mx-4 -mt-5 sm:-m-6  p-4 sm:px-6 sm:py-4 ">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Add a stock position</h3>
        <p class="mt-0 max-w-4xl text-sm text-gray-500">Please enter information below.</p>
    </div>

    <div class="mt-10 mb-6">

        <div class="space-y-12">


            <div class="grid grid-cols-12 gap-x-4 gap-y-6 sm:grid-cols-12">
                <div class="col-span-12 sm:col-span-8">
                    <SelectStockComponent v-model="onboardingStore.stepsData.addStockComponent.selectedStock"></SelectStockComponent>
                </div>


                <div v-if="showOther" class="sm:col-span-4 hidden sm:block">
                </div>

                <div v-if="showOther" class="col-span-3">
                    <label for="count" class="block text-sm font-medium leading-6 text-gray-900">Count</label>
                    <div class="mt-2">
                        <input v-model="onboardingStore.stepsData.addStockComponent.count" id="count" name="count" type="number" autocomplete="off"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div v-if="showOther" class="col-span-4">
                    <label for="avgprice" class="block text-sm font-medium leading-6 text-gray-900">Average price</label>
                    <div class="mt-2">
                        <input v-model="onboardingStore.stepsData.addStockComponent.averagePrice" id="avgprice" name="avgprice" type="number" autocomplete="off"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div v-if="showOther" class="col-span-5">
                    <label for="tdta" class="block text-sm font-medium leading-6 text-gray-900">Purchase Date</label>
                    <div class="mt-2">
                        <input v-model="onboardingStore.stepsData.addStockComponent.date" id="tdta" name="tdta" type="date" autocomplete="off"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>



            </div>




        </div>



    </div>


</template>