export function arrayDifferenceAndIntersection<T, K extends keyof T>(arr1: T[], arr2: T[] | null, key: K): { inFirstNotInSecond: T[]; inSecondNotInFirst: T[]; inBoth: T[] } {
  // Items in arr1 but not in arr2
  const inFirstNotInSecond = arr2 === null ? arr1 : arr1.filter((item1) => !arr2.some((item2) => item1[key] === item2[key]));

  // Items in arr2 but not in arr1
  const inSecondNotInFirst = arr2 === null ? [] : arr2.filter((item2) => !arr1.some((item1) => item1[key] === item2[key]));

  // Items in both arrays
  const inBoth = arr2 === null ? [] : arr1.filter((item1) => arr2.some((item2) => item1[key] === item2[key]));

  return { inFirstNotInSecond, inSecondNotInFirst, inBoth };
}
