import ApiService from "@/types/ApiService";
import type { IAssetType, IOnboardingStep } from "@/types/IAssetType";
import { AccountTypeEnum, CreateNewAccountSuggestionTypeEnum, type ApiResult, type CreateNewAccountRequest, type CreateNewAccountResult, type CreateNewStockTransactionRequest, type CreateNewStockTransactionResult, type SearchStockTickerResultItem } from "@/types/SharedTypes";
import { defineStore } from "pinia";

export const useOnboardingStore = defineStore("onboarding", {
  state: () => ({
    steps: [
      { name: "Step 1", show: true, tag: "SelectAssetTypes", canGoNext: false, canGoBack: false },
      { name: "Step 4", show: undefined, tag: "AddCashAccount", canGoNext: false, canGoBack: true, assetType: "Cash" },
      { name: "Step 2", show: undefined, tag: "AddStockAccount", canGoNext: false, canGoBack: true, assetType: "Stocks" },
      { name: "Step 3", show: undefined, tag: "AddStock", canGoNext: false, canGoBack: true, assetType: "Stocks" },
      { name: "Step 5", show: undefined, tag: "Unknown", canGoNext: false, canGoBack: true },
      { name: "Step 6", show: true, tag: "Final", canGoNext: false, canGoBack: true },
    ] as IOnboardingStep[],
    currentStepInner: undefined as undefined | IOnboardingStep,
    assetTypes: [
      {
        title: "Cash",
        description: "Get notified when someones posts a comment on a posting.",
        isSelected: false,
      },
      {
        title: "Stocks",
        description: "Get notified when someones posts a comment on a posting.",
        isSelected: false,
      },
      // {
      //   title: "Funds",
      //   description: "Get notified when someones posts a comment on a posting.",
      //   isSelected: false,
      // },
      // {
      //   title: "Coins",
      //   description: "Get notified when someones posts a comment on a posting.",
      //   isSelected: false,
      // },
      // {
      //   title: "Commodities",
      //   description: "Get notified when someones posts a comment on a posting.",
      //   isSelected: false,
      // },
    ] as IAssetType[],
    stepsData: {
      addStockAccountComponent: {
        accountName: null as null | string,
        currency: "USD",
        createdAccountId: null as null | number,
      },
      addCashAccountComponent: {
        accountName: null as null | string,
        currentBalance: null as null | number,
        currency: "USD",
        createdAccountId: null as null | number,
      },
      addStockComponent: {
        selectedStock: undefined as undefined | SearchStockTickerResultItem,
        count: null as null | number,
        averagePrice: null as null | number,
        date: null as null | string,
        createdStockTransactionId: null as null | number,
      },
    },
    progressSentence: undefined as undefined | string,
    progressMessages: [] as string[],
    setupDone: false as boolean,
    show: false as boolean,
  }),
  actions: {
    setCurrentState(step: IOnboardingStep) {
      this.currentStepInner = step;
    },
    goBack() {
      if (this.getCanGoBack) {
        if (this.getCurrentStep) {
          const currentStepIndex = this.getSteps.findIndex((x) => x.name === this.getCurrentStep.name);
          if (currentStepIndex >= 0) {
            const previousStep = this.getSteps[currentStepIndex - 1];
            if (previousStep) {
              this.setCurrentState(previousStep);
            }
          }
        }
      }
    },
    goNext() {
      if (this.getCanGoNext) {
        if (this.getCurrentStep) {
          const currentStepIndex = this.getSteps.findIndex((x) => x.name === this.getCurrentStep.name);
          if (currentStepIndex >= 0) {
            const nextStep = this.getSteps[currentStepIndex + 1];
            if (nextStep) {
              this.setCurrentState(nextStep);
            }
          }
        }
      }
    },
    addProgressMessage(message: string) {
      this.progressSentence = message;
      this.progressMessages.push(message);
    },
    async setupMyAccount() {
      var result = false;
      result = await this.createNewCashAccount();
      if (result) {
        result = await this.createNewStockInvestingAccount();
        if (result) {
          result = await this.addStockTransaction();
        }
      }
      if (result === true) {
        this.setupDone = true;
      }
    },
    async createNewCashAccount(): Promise<boolean> {
      if (this.stepsData.addCashAccountComponent.accountName === undefined || this.stepsData.addCashAccountComponent.accountName === null || this.stepsData.addCashAccountComponent.accountName.length === 0) {
        return false;
      }
      if (this.stepsData.addCashAccountComponent.currency === undefined || this.stepsData.addCashAccountComponent.currency === null || this.stepsData.addCashAccountComponent.currency.length === 0) {
        return false;
      }
      this.addProgressMessage("Creating cash account.");
      const createNewAccountRequest = {
        accountType: AccountTypeEnum.Cash,
        currency: this.stepsData.addCashAccountComponent.currency,
        name: this.stepsData.addCashAccountComponent.accountName,
        userChoice: null,
        description: null,
        amount: this.stepsData.addCashAccountComponent.currentBalance,
      } as CreateNewAccountRequest;
      const apiService = new ApiService<ApiResult<CreateNewAccountResult>>();
      try {
        const response = await apiService.post("/Account/CreateNewAccount", createNewAccountRequest, null);
        if (response.data && response.data.error) {
          this.addProgressMessage("Error in creating cash account.");
          return false;
        } else {
          this.stepsData.addCashAccountComponent.createdAccountId = response.data.result.createdAccountId;
          this.addProgressMessage("Cash account created.");
          return true;
        }
      } catch (error) {
        this.addProgressMessage("Error in creating cash account.");
        return false;
      }
    },
    async createNewStockInvestingAccount(): Promise<boolean> {
      if (this.stepsData.addStockAccountComponent.accountName === undefined || this.stepsData.addStockAccountComponent.accountName === null || this.stepsData.addStockAccountComponent.accountName.length === 0) {
        return false;
      }
      if (this.stepsData.addStockAccountComponent.currency === undefined || this.stepsData.addStockAccountComponent.currency === null || this.stepsData.addStockAccountComponent.currency.length === 0) {
        return false;
      }
      this.addProgressMessage("Creating stock investing account.");
      const createNewAccountRequest = {
        accountType: AccountTypeEnum.InvestingForStocks,
        currency: this.stepsData.addStockAccountComponent.currency,
        name: this.stepsData.addStockAccountComponent.accountName,
        userChoice: CreateNewAccountSuggestionTypeEnum.OpenNewCashForStocksAccount,
        description: null,
        amount: null,
      } as CreateNewAccountRequest;
      const apiService = new ApiService<ApiResult<CreateNewAccountResult>>();
      try {
        const response = await apiService.post("/Account/CreateNewAccount", createNewAccountRequest, null);
        if (response.data && response.data.error) {
          this.addProgressMessage("Error in creating stock investing account.");
          return false;
        } else {
          this.addProgressMessage("Stock investing account created." + response.data.result.createdAccountId);
          this.stepsData.addStockAccountComponent.createdAccountId = response.data.result.createdAccountId;
          return true;
        }
      } catch (error) {
        this.addProgressMessage("Error in creating stock investing account.");
        return false;
      }
    },
    async addStockTransaction(): Promise<boolean> {
      const { selectedStock, count, averagePrice, date } = this.stepsData.addStockComponent;

      if (!selectedStock || !count || !averagePrice || !date) {
        this.addProgressMessage("Missing required stock transaction information.");
        return false;
      }

      if (this.stepsData.addStockAccountComponent.createdAccountId === null) {
        this.addProgressMessage("Stock investing account not created yet.");
        return false;
      }

      this.addProgressMessage("Adding stock transaction...");

      const createNewStockTransactionRequest: CreateNewStockTransactionRequest = {
        buyOrSell: "BUY",
        commission: null,
        count: count,
        description: "Created during onboarding",
        dontDeductFromCashAccount: true,
        investingAccountId: this.stepsData.addStockAccountComponent.createdAccountId,
        stockTickerId: selectedStock.id,
        transactionDate: date,
        stockPrice: averagePrice,
      };

      const apiService = new ApiService<ApiResult<CreateNewStockTransactionResult>>();
      try {
        const response = await apiService.post("/StockTransaction/CreateNewStockTransaction", createNewStockTransactionRequest, null);
        if (response.data && response.data.error) {
          this.addProgressMessage(`Error in adding stock transaction: ${response.data.error}`);
          return false;
        } else {
          this.addProgressMessage("Stock transaction added successfully.");
          return true;
        }
      } catch (error) {
        this.addProgressMessage(`Error in adding stock transaction: ${error instanceof Error ? error.message : "Unknown error"}`);
        return false;
      }
    },
    logoff() {
      this.currentStepInner = undefined;
      this.stepsData = {
        addStockAccountComponent: {
          accountName: null as null | string,
          currency: "USD",
          createdAccountId: null as null | number,
        },
        addCashAccountComponent: {
          accountName: null as null | string,
          currentBalance: null as null | number,
          currency: "USD",
          createdAccountId: null as null | number,
        },
        addStockComponent: {
          selectedStock: undefined as undefined | SearchStockTickerResultItem,
          count: null as null | number,
          averagePrice: null as null | number,
          date: null as null | string,
          createdStockTransactionId: null as null | number,
        },
      };
      this.progressSentence = undefined;
      this.setupDone = false;
      this.progressMessages = [];
    },
  },
  getters: {
    getCurrentStep(state): IOnboardingStep {
      if (state.currentStepInner === undefined) {
        state.currentStepInner = state.steps[0];
      }
      return state.currentStepInner;
    },
    getSteps(state): IOnboardingStep[] {
      return state.steps.filter((x) => x.show !== undefined && x.show === true);
    },
    getCurrentStepIndex(state): number {
      return this.getSteps.findIndex((x) => x.name === this.getCurrentStep.name);
    },
    getStepIndex(state) {
      return (step: IOnboardingStep) => this.getSteps.findIndex((x) => x.name === step.name);
    },
    getCanGoNext(state): boolean {
      return this.getCurrentStep.canGoNext;
    },
    getCanGoBack(state): boolean {
      if (this.setupDone) return false;
      return this.getCurrentStep.canGoBack;
    },
  },
});
