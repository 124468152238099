export class MyExchangeRate {
  symbol!: string;
  price!: number;
  date!: string;

  constructor(symbol: string, price: number, date: string) {
    this.symbol = symbol;
    this.price = price;
    this.date = date;
  }
}
