import type { MyAccount } from "./MyAccount";
import type { MyCoinTicker } from "./MyCoinTicker";
import { NoteTypeEnum, type GetNotesResultItem } from "./SharedTypes";

export class MyCoinPosition {
  id!: number;
  accountId!: number;
  coinTickerId!: number;
  count!: number;
  averagePrice!: number;
  averagePriceInUsd!: number;
  amount!: number;

  // account!: MyAccount;
  coinTicker!: MyCoinTicker | null;
  account!: MyAccount | null;

  // currentAmount!: number | null;

  notes!: null | GetNotesResultItem[];

  constructor(id: number, accountId: number, coinTickerId: number, count: number, averagePrice: number, averagePriceInUsd: number) {
    this.id = id;
    this.accountId = accountId;
    this.coinTickerId = coinTickerId;
    this.count = count;
    this.averagePrice = averagePrice;
    this.averagePriceInUsd = averagePriceInUsd;
    this.coinTickerId = coinTickerId;

    // this.account = account;
  }

  addNote(getNotesResultItem: GetNotesResultItem) {
    if (this.notes !== undefined || this.notes !== null) {
      this.notes = [];
    }
    this.notes.push(getNotesResultItem);
  }

  get getHasNoteTaskToDo(): boolean {
    if (this.notes !== undefined && this.notes !== null) {
      return this.notes.some((x) => x.noteType === NoteTypeEnum.TaskToDo);
    }
    return false;
  }

  get getHasNoteTaskCompleted(): boolean {
    if (this.getHasNoteTaskToDo === true) return false;
    if (this.notes !== undefined && this.notes !== null) {
      return this.notes.some((x) => x.noteType === NoteTypeEnum.TaskCompleted);
    }
    return false;
  }

  get getHasNoteStandartNote(): boolean {
    if (this.getHasNoteTaskToDo === true || this.getHasNoteTaskCompleted === true) return false;
    if (this.notes !== undefined && this.notes !== null) {
      return this.notes.some((x) => x.noteType === NoteTypeEnum.StandartNote);
    }
    return false;
  }

  get getYesterdayAmount(): number | undefined {
    if (this.coinTicker) {
      if (this.coinTicker.lastClosePrice) {
        return this.coinTicker.lastClosePrice * this.count;
      }
    }
  }

  get getYesterdayAmountInUsd(): number | undefined {
    if (this.account) {
      if (this.account.currency === "USD") {
        return this.getYesterdayAmount;
      } else {
        if (this.getYesterdayAmount && this.account.exchangeRate) {
          return this.getYesterdayAmount / this.account.exchangeRate?.price;
        }
      }
    }
    return undefined;
  }

  get getCurrentAmount(): number | undefined {
    if (this.coinTicker) {
      if (this.coinTicker.currentPrice) {
        return this.coinTicker.currentPrice * this.count;
      }
      if (this.coinTicker.lastClosePrice) {
        return this.coinTicker.lastClosePrice * this.count;
      }
    }
  }

  get getGurrentAmountInUsd(): number | undefined {
    if (this.account) {
      if (this.account.currency === "USD") {
        return this.getCurrentAmount;
      } else {
        if (this.getCurrentAmount && this.account.exchangeRate) {
          return this.getCurrentAmount / this.account.exchangeRate?.price;
        }
      }
    }
    return undefined;
  }

  get getProfit(): number | undefined {
    if (this.getCurrentAmount) {
      return this.getCurrentAmount - this.averagePrice * this.count;
    }
    return undefined;
  }

  get getProfitInUsd(): number | undefined {
    if (this.getGurrentAmountInUsd) {
      return this.getGurrentAmountInUsd - this.averagePriceInUsd * this.count;
    }
    return undefined;
  }

  get getCost(): number {
    return this.averagePrice * this.count;
  }

  get getCostInUsd(): number {
    return this.averagePriceInUsd * this.count;
  }

  get getDayProfit(): number | undefined {
    if (this.coinTicker && this.coinTicker.isMarketOpen && this.coinTicker.currentPrice && this.coinTicker.lastClosePrice) {
      return (this.coinTicker.currentPrice - this.coinTicker.lastClosePrice) * this.count;
    }
    return undefined;
  }

  get getDayChange(): number | undefined {
    if (this.coinTicker && this.coinTicker.isMarketOpen && this.coinTicker.currentPrice && this.coinTicker.lastClosePrice) {
      return (this.coinTicker.currentPrice - this.coinTicker.lastClosePrice) / this.coinTicker.lastClosePrice;
    }
    return undefined;
  }

  get getProfitRatio(): number | undefined {
    if (this.getProfit) {
      return this.getProfit / this.getCost;
    }
    return undefined;
  }

  get getProfitRatioInUsd(): number | undefined {
    if (this.getProfitInUsd) {
      return this.getProfitInUsd / this.getCostInUsd;
    }
    return undefined;
  }

  setCoinTickerPrice(newPrice: number) {
    // if (this.coinTicker) {
    //   this.currentAmount = newPrice * this.count;
    // }
  }
}
